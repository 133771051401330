import { ProductConfiguratorFields } from './formFields'

const PROTESICHE_MULTIPLE = {
  'ELEMENTO DI SUPPORTO IMPLANTARE': {
    multipla_conometrica: 'MULTIPLA CONOMETRICA',
    barra: 'BARRA',
    // controbarra: 'CONTROBARRA',
    direct_toronto: 'DIRECT/TORONTO'
  },
  'DENTE PREPARATO': {
    sovrastruttura: 'SOVRASTRUTTURA',
    multipla_cementata: 'MULTIPLA CEMENTATA'
  }
}

export const MULTIPLE_ADDITIONAL_ELEMENT_TYPE = ['extension_element', 'intermediate_element']

export const ALL_PROTESICHE_MULTIPLE = {
  multipla_conometrica: 'MULTIPLA CONOMETRICA',
  barra: 'BARRA',
  // controbarra: 'CONTROBARRA',
  direct_toronto: 'DIRECT/TORONTO',
  multipla_cementata: 'MULTIPLA CEMENTATA',
  sovrastruttura: 'SOVRASTRUTTURA'
}

export const TIPOLOGIE_SINGOLE = {
  supporto_implantare: 'ELEMENTO DI SUPPORTO IMPLANTARE',
  dente_preparato: 'DENTE PREPARATO'
}

export const PROTESICHE_SINGOLE = {
  'ELEMENTO DI SUPPORTO IMPLANTARE': {
    corona_conometrica: 'CORONA CONOMETRICA',
    corona_avvitata: 'CORONA AVVITATA',
    abutment: 'ABUTMENT PERSONALIZZATO',
    corona_cementata: 'CORONA CEMENTATA'
  },
  'DENTE PREPARATO': {
    corona_cementata: 'CORONA CEMENTATA'
  }
}

export const PROTOTYPE_MODEL_TYPE = 'prototype_model'

export const tipologiaInternalKey = configValue => {
  return Object.keys(TIPOLOGIE_SINGOLE).find(k => TIPOLOGIE_SINGOLE[k] === configValue)
}

const protesicheReducer = (acc, item) => Object.assign(acc, item)

export const protesicaInternalKey = configValue => {
  const allSingleProtesiche = Object.values(PROTESICHE_SINGOLE).reduce(protesicheReducer, {})
  const allMultipleProtesiche = Object.values(PROTESICHE_MULTIPLE).reduce(protesicheReducer, {})
  const allDefinitions = { ...allSingleProtesiche, ...allMultipleProtesiche }

  return Object.keys(allDefinitions).find(k => allDefinitions[k] === configValue)
}

export const tipologiaFromProtesicaInternalKey = configValue => {
  return Object.keys(PROTESICHE_MULTIPLE).find(tipologia => {
    const protesicheForTipologia = PROTESICHE_MULTIPLE[tipologia]
    return Object.values(protesicheForTipologia).includes(configValue)
  })
}

export class Selection {
  constructor(workingUnit) {
    this.workingUnit = workingUnit
  }

  isPermitted() {
    return !this.workingUnit.confirming
  }

  canSelectElement(number) {
    return this.selectableElements().includes(number)
  }

  selectableElements() {
    const maxPosition = Math.max(...this.orderedElementIndexes())
    const minPosition = Math.min(...this.orderedElementIndexes())
    return [
      this.currentArch()[minPosition - 1],
      this.currentArch()[maxPosition + 1]
    ]
  }

  minElementNumber() {
    return this.orderedElementNumbers()[0]
  }

  maxElementNumber() {
    return this.orderedElementNumbers()[this.orderedElementNumbers().length - 1]
  }

  orderedElementNumbers() {
    return this.orderedElementIndexes().map(index => this.currentArch()[index])
  }

  orderedElementIndexes() {
    return this.elementNumbers()
               .map(elNumber => this.currentArch().indexOf(elNumber))
               .sort()
  }

  currentArch() {
    const firstElement = this.elementNumbers()[0]
    return firstElement > 30 ? this.#LOWER_ARCH_ELEMENTS : this.#UPPER_ARCH_ELEMENTS
  }

  elementNumbers() {
    return this.workingUnit.elements.map(element => element.elemento)
  }

  #UPPER_ARCH_ELEMENTS = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28]
  #LOWER_ARCH_ELEMENTS = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38]
}

class Element {
  #HEADER_KEYS_TO_EXCLUDE = ['protesica', 'tipologia', 'totale_elementi', 'totale_supporti']

  constructor(element, workingUnitHeader, workingUnitType) {
    this.element = element
    this.workingUnitHeader = workingUnitHeader
    this.workingUnitType = workingUnitType
  }

  fieldsOrder = () => this.#onlyFieldsToDisplay(
    new ProductConfiguratorFields({
      header: this.workingUnitHeader,
      type: this.workingUnitType
    }).allVisibleKeys()
  )

  #onlyFieldsToDisplay(allOrderedFields = []) {
    if (this.element.type) { return [] }

    let headerFields = Object.keys(this.workingUnitHeader)
    headerFields = headerFields.filter(field => !this.#HEADER_KEYS_TO_EXCLUDE.includes(field))
    const elementFields = Object.keys(this.element)
    const fieldsToDisplay = [...headerFields, ...elementFields]
    return allOrderedFields.filter(field => fieldsToDisplay.includes(field))
  }
}

export class WorkingUnit {
  constructor(workingUnit) {
    this.workingUnit = workingUnit
  }

  elementFieldsOrder(elementNumber) {
    return this.#elementObjectByNumber(elementNumber).fieldsOrder()
  }

  #elementObjectByNumber = (number) => new Element(
    this.workingUnit.elements.find(({ elemento }) => elemento === number),
    this.workingUnit.header,
    this.workingUnit.type
  )
}

export class PrototypeModel {
  constructor(workingUnit) {
    this.workingUnit = workingUnit
  }

  configurationFieldsOrder() {
    return new Element(this.workingUnit.configuration, {}, this.workingUnit.type).fieldsOrder()
  }
}
